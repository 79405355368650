export enum ProductAvailability {
  AVAILABLE = 'available',
  PERMANENTLY_AVAILABLE = 'permanentlyAvailable',
  NOT_BUYABLE = 'notBuyable',
  TMP_NOT_BUYABLE = 'tmpNotBuyable',
  STOCK_DEPENDENT = 'stockDependent',
  DISCONTINUED_ITEM = 'discontinuedItem',
}

export type ProductAvailabilityState = ProductAvailability;
